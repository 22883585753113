.edit-feature-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #121863;

  .feature-checkbox {
    background-color: #121863;
  }
}

.edit-feature-period {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.feature-input-times {
  margin-left: 10px;
  margin-right: 10px;
  width: 80px;
  text-align-last: center;
}

.times {
  color: #7b7b7b;
}
