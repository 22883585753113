@import '../../../styles/_colors-variables.scss';

// .modal-assign-org
.modal-default.modal-assign-org {
    background-color: rgba(0, 0, 0, 0.2);

    .modal-mains {
        margin: 330px auto 50px;
        width: 1080px;
        height: auto;
        padding: 40px;

        .btn-close {
            width: 32px;
            height: 32px;

            &:hover {
                opacity: 0.9;
            }
        }

        .filter-bar {
            padding: 15px 0 24px;

            .title {
                font-weight: 700;
                font-size: 24px;
                line-height: 35px;
                color: #121863;
            }
        }

        .overlay {
            &.cover{
                background-color: rgba(128, 128, 128, 0.5);
                pointer-events: none;
            }
            .content-selection-wrap {
                padding: 0 15px;
                overflow-x: auto;

                margin: 0 -40px 0 -10px;

                &::-webkit-scrollbar {
                    height: 4px;
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba(201, 210, 223, 0.4);
                    border-radius: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 7px;
                    background-color: #C9D2DF;
                }

                &::-webkit-scrollbar-button {
                    background-color: transparent;
                }

                &::-webkit-scrollbar-corner {
                    background-color: black;
                }

                .selection-wrap {

                    display: inline-flex;
                    flex-direction: column;
                    max-height: 442px;
                    flex-wrap: wrap;

                    .group-title {
                        font-weight: 700;
                        font-size: 14px;
                        min-height: 32px;
                        margin-bottom: 24px;
                        border-bottom: 2px solid #C9D2DF;
                        width: max-content;
                    }

                    .items {
                        margin-bottom: 24px;
                        margin-right: 120px;
                        min-height: 38px;
                    }
                }
            }
        }

        .registered-list {
            padding: 24px 0 24px;

            .groups {
                margin-bottom: 24px;

                .color-block {
                    display: inline-flex;
                    align-items: center;
                    padding: 12px;
                    border-radius: 8px;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 25px;
                    color: #121863;
                    margin-bottom: 12px;

                    &.pink {
                        background: rgba(219, 48, 63, 0.05);
                    }

                    &.blue {
                        background: rgba(50, 65, 255, 0.05);
                    }

                    .number {
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 38px;
                        color: #121863;
                        margin-left: 12px;
                    }
                }

                .p-txt {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 17px;
                    color: #121863;
                }
            }
        }

        .bottom-btns {
            margin-top: 0;
        }
    }
}