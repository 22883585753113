@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

// .suggestions-module
.suggestions-module {
  margin-bottom: 24px;
  .titles {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #121863;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    small {
      margin-left: 16px;
      font-weight: 500;
    }
  }
  .suggestion-list {
    button {
      text-decoration: underline;
      font-weight: bold;
      position: relative;
      background-color: transparent;
      color: inherit;
      border: 0;
      .facList {
        position: absolute;
        top: 100%;
        left: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px 12px;
        background: #f5f5ff;
        border-radius: 16px;
        z-index: 1;
        width: 450px;
        max-height: 0;
        padding: 0 16px;
        transition: 0.3s;
        overflow: hidden;
        box-sizing: border-box;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 1.25;
          text-align: left;
        }

        &.show {
          max-height: 186px;
          padding: 16px;
          border: 1px solid #121863;
          overflow: scroll;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    li {
      margin-bottom: 12px;
    }
    .txt {
      padding-left: 20px;
      font-weight: 400;
      font-size: 17px;
      line-height: 1.47;
      letter-spacing: 0.005em;
      color: #121863;
      position: relative;
      a {
        color: rgba(50, 65, 255, 1);
        text-decoration: underline;
        font-weight: 700;
      }
      .points {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #3241ff;
        display: inline-block;
        margin-right: 5px;
        position: absolute;
        left: 5px;
        top: 12px;
      }

      strong {
        display: block;
      }

      .body {
        white-space: pre-wrap;
      }
    }

    li.no-suggestion {
      .txt {
        padding-left: 0;
      }
    }
  }
  .show-all {
    padding: 20px 0 0;
    text-align: center;
    .btn-show,
    .btn-hide {
      font-size: 13px;
      line-height: 19px;
      text-align: right;
      color: #121863;
    }
    .btn-hide {
      .icon-drop {
        transform: rotateX(180deg);
      }
    }
    .icon-drop {
      background: url("/assets/icon-drop.svg") no-repeat center center;
      background-size: 10px 7px;
      width: 10px;
      height: 7px;
      margin-left: 5px;
      transform: rotateX(0);
    }
  }

  @media #{$smartphone-breakpoint} {
    .titles {
      .rights {
        small {
          font-size: 12px;
        }
      }
    }

    .suggestion-list {
      .txt {
        font-size: $sp-main-text-size;
      }
    }
  }
}
