.modal-popup-menu {
  ul {
    li {
      margin: 4px 0;

      .link {
        font-weight: 500;
        color: rgb(13, 110, 253);
      }
    }
  }
}
