.feature-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: fit-content;
  max-width: 800px;
}

.feature-item-container {
  margin: 5px 5px;
  border: 1px solid #3241ff;
  border-radius: 20px;
  color: #3241ff;
  height: 40px;
  line-height: 36px;
  width: fit-content;
  padding: 1px 20px;
  font-size: 16px;
  font-weight: 700;
}
