@import "../../../styles/_responsive-variables.scss";

.mass-post-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .mass-post-locations-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .mass-post-contents-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 648px;
    padding: 24px;

    h3 {
      font-size: 18px;
      padding-left: 12px;
      font-weight: 700;
      line-height: 28px;
    }

    .bottom-btns {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .btn {
        min-width: 160px;
      }
    }

    .modal-preview-local-post {
      .modal-mains {
        padding: 40px 10px;
        width: 750px;
        height: unset;
      }
    }

    @media #{$tablet-breakpoint} {
      max-width: 100%;
      .bottom-btns {
        flex-direction: column;
        align-items: stretch;
        div {
          flex-direction: column;
          align-items: stretch;
        }
      }
    }
  }
}
