@import '../../../styles/_colors-variables.scss';

// .modal-assign-selection-confirm
.modal-default.modal-assign-selection-confirm {
    background-color: rgba(0,0,0,0.2);
    .modal-mains {
        width: 600px;
        height: auto;
        padding-bottom: 60px;
        .btn-close {
            width: 33px;
            height: 33px;
            &:hover {
                opacity: 0.9;
            }
        }
        .p-txt {
            font-weight: 400;
            font-size: 17px;
            line-height: 25px;
            color: #121863;
            margin-bottom: 24px;
        }
        .bottom-btns {
            .btn {
                min-width: 220px;
            }
        }
    }
}