.feature-item {
  border-bottom: 1px solid black;
  width: 100%;
}

.edit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}