@import '../../../styles/_colors-variables.scss';

.modal-default.modal-send-message {
    .modal-mains {
        width: 1080px;
        height: auto;
        max-height: 80vh;
        padding: 40px 0;

        h3 {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
        }

        .inputs, .textarea {
            margin: 8px 0 24px;
        }
    }

    .modal-send-message-body {
        overflow: scroll;
        max-height: calc(80vh - 80px);
        padding: 0 40px;

        .current-filters {
            margin-bottom: 8px;
        }
    }

    + .filter-panel {
        z-index: 9999;
    }
}