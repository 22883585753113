@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

.basic-profile-module {
  display: flex;
  flex-direction: row;
  gap: 16px;
  .row {
    flex-grow: 1;
  }
  .data-group {
    word-break: break-all;
    .data-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.015em;
      color: #757799;
      margin-bottom: 6px;
    }
    .values {
      font-weight: 400;
      font-size: 17px;
      line-height: 1.47;
      letter-spacing: 0.005em;
      color: #121863;
    }
    .sub-values {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.47;
      letter-spacing: 0.005em;
      color: #121863;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media #{$tablet-breakpoint} {
    flex-direction: column;
  }

  @media #{$smartphone-breakpoint} {
    .row {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 1fr 1fr;

      .sp-row {
        grid-column: 1 / span 2;
      }
    }
    .data-group {
      .values {
        font-size: $sp-main-text-size;
      }
    }
  }
}
