.edit-profile-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .message-body {
    .error {
      padding: 8px 0 0 21px;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      display: flex;
      align-items: center;
      letter-spacing: 0.015em;
      color: #c70000;
      &:before {
        content: "";
        display: block;
        background: url(/assets/warning.svg) center center no-repeat;
        background-size: 15px 13px;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0px;
        top: 10px;
      }
    }
    .message-body-input {
      height: 320px;
      max-height: 60vh;
      padding: 0;
      display: flex;
      justify-content: stretch;
      align-items: stretch;

      textarea {
        padding: 8px 16px;
        height: unset;
        line-height: 125%;
      }
    }
  }
}
