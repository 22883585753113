#checklistTable {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 16px;
  max-width: 450px;
  align-items: center;

  h4 {
    margin: 0.5em 0;
    font-size: 18px;
  }

  > div {
    content: '';
    width: 30px;
    height: 30px;
    background: {
      repeat: no-repeat;
      size: contain;
    }

    &.pass {
      background-image: url(../images/good.svg);
    }

    &.fail {
      background-image: url(../images/danger.svg);
    }
  }

  .icons {
    width: 20px;
    height: 20px;
    &.icon-done {
        background: rgba(122,198,63,0.3) url(/assets/green-done.svg) center center no-repeat;
        background-size: 20px;
        border-radius: 100%;
    }
    &.icon-info {
        background: url(/assets/info.svg) center center no-repeat;
        background-size: 23px;
    }
    &.icon-error {
        background: url(/assets/error.svg) center center no-repeat;
        background-size: 20px;
    }
    &.icon-no-data {
        background: url(/assets/no-data.svg) center center no-repeat;
        background-size: 20px;
    }
}
}