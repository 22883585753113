.select-custom-dashboard {
  margin: 0px;
}

.select-custom-dashboard,
.custom-dashboard-select__bar {
  display: flex;
  align-items: center;
  width: fit-content;

  > * + * {
    margin-left: 16px;
  }
}

.custom-dashboard-select {
  margin-top: 32px;
  &__bar {
    & + & {
      margin-top: 16px;
    }

    .inputs-wrap {
      min-width: 200px;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #121863;
    margin-bottom: 16px;
    margin-bottom: 0;
  }

  .current-filters {
    display: flex;
  }
}
