@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

// .facility-registration-top-info-module
.facility-registration-top-info-module {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  white-space: pre;
  .description-txt {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #121863;
    margin-bottom: 10px;
  }
  .sign-up-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #121863;
    padding-bottom: 10px;
    .sign-up-date {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;
      font-weight: bold;
      font-size: 24px;
      line-height: 20px;
      white-space: nowrap;
      flex-wrap: wrap;
      color: #121863;
      .location-percentage {
        font-size: 18px;
      }
    }
    .update-date {
      margin-top: 10px;
      font-weight: bold;
    }
  }

  @media #{$smartphone-breakpoint} {
    flex-direction: column-reverse;

    .sign-up-container {
      align-items: flex-start;
      border-bottom: 2px solid #c4c4c4;
      margin-bottom: 8px;

      .update-date {
        margin-top: 4px;
      }
    }
  }
}
