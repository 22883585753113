.rec-message-table-module {
  .row-th, .row-td {
    .spacing {
      padding: 0 16px;
    }
    > div:nth-child(1) {
      width: 110px;
      text-align: center;
    }
    > div:nth-child(2) {
      width: 250px;
    }
    > div:nth-child(4) {
      width: 240px;
      text-align: center;
    }
    > div:nth-child(5) {
      width: 200px;
    }
  }
}
