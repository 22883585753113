@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

// .popup-select-labels
.popup-select-labels {
  position: absolute;
  width: 300px;
  left: -195px; // -70px
  top: 30px;
  z-index: 886;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: block;
  .popup-title-bar {
    padding: 16px 11px;
    border-bottom: 1px solid #c9d2df;
    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.015em;
      color: #121863;
    }
  }
  .check-list {
    padding: 24px 16px;
    max-height: 200px;
    overflow: auto;
    ul {
      li {
        margin-bottom: 3px;
      }
    }
  }
  .bottom-btn {
    padding: 0 16px 16px;
    text-align: center;
    .btn-blue {
      padding: 1px 24px;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #ffffff;
      line-height: 38px;
      .icon-add {
        background: url("/assets/white-plus.svg") center center no-repeat;
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        margin-top: -4px;
      }
    }
  }

  @media #{$smartphone-breakpoint} {
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
