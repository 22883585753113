@import "../../../styles/_colors-variables.scss";

.cant-find-post {
  font-size: 12px;
  margin-top: 18px;
}
.recent-local-post-list {
  .local-post-list {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    justify-content: flex-start;
    height: fit-content;
    max-width: 100%;
    .no-data {
      font-size: 18px;
      color: #121863;
    }
  }
}
