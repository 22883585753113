@import "../../../styles/_colors-variables.scss";

.recent-media-list {
  .media-list {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    overflow-x: scroll;
    justify-content: flex-start;
    max-height: 270px;
    height: fit-content;
    max-width: 100%;
    .no-data {
      font-size: 18px;
      color: #121863;
    }
  }
}
