.media-item {
    background-color: white;
    width: 303px;
    height: inherit;
    margin-right: 20px;
    .media-top-info {
      height: 60px;
      display: flex;
      width: inherit;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .left-title {
        font-size: 18px;
        color: #121863;
        font-weight: 700;
        width: fit-content;
        max-width: 220px;
      }
      .right-title {
        font-size: 16px;
        color: #121863;
        font-weight: 300;
      }
    }
    .media-img-container {
      display: flex;
      flex-direction: column;
      .media-img {
        width: 100%;
        height: 200px;
        background: #f5f7fa;
        object-fit: contain;
        background-color: #5a5a5b;
      }
      .summary {
        padding: 10px;
        color: #aaa;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }