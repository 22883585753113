@import "../../styles/_responsive-variables.scss";
@import "../../styles/_colors-variables.scss";

.analysis-collections {
  // .top-title-bar
  .top-title-bar {
    padding-bottom: 20px;
    .left-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #121863;
    }
    .rights {
      .btn {
        margin-left: 16px;
        .icons {
          width: 20px;
          margin-right: 10px;
          height: 18px;
        }
        .icon-pdf {
          background: url("/assets/print.svg") center center no-repeat;
          background-size: 20px 24px;
        }
        .icon-csv {
          background: url("/assets/view_list.svg") center center no-repeat;
          background-size: 24px 24px;
        }
      }
    }
  }
  .add-chart {
    text-align: center;
    &.mt-100 {
      margin-top: 100px;
    }
    .btn-border {
      height: 56px;
      line-height: 52px;
      border-radius: 28px;
      min-width: 248px;
      font-size: 18px;
    }
    .icon-add {
      width: 27px;
      height: 27px;
      background: url("/assets/blue-add.svg") center center no-repeat;
      background-size: 27px 27px;
      margin-right: 15px;
      margin-top: -4px;
    }
  }
}
