@import '../../styles/_colors-variables.scss';

.choose-bar {
    background: #C9D2DF;
    display: flex;
    height: 40px;
    padding: 2px;
    border-radius: 20px;
    width: 173px;
    .items {
        flex: 0 0 33.3333333%;
        max-width: 33.3333333%;
        font-weight: 700;
        font-size: 16px;
        color: #fff;
        line-height: 34px;
        text-align: center;
        &.current {
            padding: 1px 12px;
            width: auto;
            height: 100%;
            background: #FFFFFF;
            border-radius: 18px;
            height: 100%;
            color: #757799;
        }
    }
}