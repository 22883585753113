.suggestion-body {
  button {
    text-decoration: underline;
    font-weight: bold;
    position: relative;
    background-color: transparent;
    color: inherit;
    border: 0;
    .facList {
        position: absolute;
        top: 100%;
        left: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px 12px;
        background: #F5F5FF;
        border-radius: 16px;
        z-index: 1;
        width: 450px;
        max-height: 0;
        padding: 0 16px;
        transition: 0.3s;
        overflow: hidden;
        box-sizing: border-box;
        align-items: center;
        a {
            font-weight: 700;
            font-size: 12px;
            line-height: 1.25;
            text-align: left;
            text-decoration: underline;
            color: #121863 !important;
        }

        &.show {
            max-height: 186px;
            padding: 16px;
            border: 1px solid #121863;
            overflow: scroll;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
  }
}