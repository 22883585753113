@import '../../../styles/_colors-variables.scss';

.modal-default.modal-create-save-collection {
  .modal-mains {
    .title-bar {
        .little-txt {
            &.required {
                font-weight: 700;
                font-size: 12px;
                line-height: 17px;
                color: #DC5B3E;
            }
        } 
    }
    .inputs {
        height: 53px;
        input {
            color: #121863;
        }
    }
  }
}