.review-summary-comments-chart-container {
  .legend-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    gap: 8px;

    .legend-positive {
      color: #7ac63f;
    }

    .legend-negative {
      color: #96001b;
    }
  }
}
