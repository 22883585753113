@import "../../../../styles/_responsive-variables.scss";

.comments-section {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  gap: 24px;

  .point {
    display: flex;
    flex-direction: column;
    .point-header {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: flex-start;
      .point-title {
        font-size: 16px;
        font-weight: 700;
        color: #121863;
      }
      .point-count {
        min-width: max-content;
      }
    }
  }

  @media #{$tablet-breakpoint} {
    flex-direction: column;
  }

  .section {
    flex: 1;
  }
}
