@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

.members-list {

  .member-table {
    grid-template-columns: 2fr 90px 2fr 120px 1fr;
  }

  &.white-border-panel {
    padding-bottom: 0;
  }

  .strong-txt {
    font-weight: bold;
  }

  .top-bar {
    padding: 0 32px 20px;

    .left-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #121863;
    }

    .btn-blue {
      min-width: 165px;
      line-height: 38px;
    }
  }

  .table-data {
    .row-th {
      padding: 0 26px;
      height: 48px;

      .spacing {
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.015em;
        color: #121863;
      }
    }

    .row-td {
      padding: 18px 24px;

      &:last-child {
        border-bottom: 0;
      }

      .strong-txt {
        font-weight: 700;
        font-size: 17px;
        line-height: 25px;
        letter-spacing: 0.0015em;
        color: #121863;
      }
    }

    .blue-link {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #3241ff;
      text-decoration: none;
    }

    .gray-link {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #757799;
    }

    .w10 {
      width: 10%;
    }
  }

  @media #{$smartphone-breakpoint} {
    .top-bar {
      flex-direction: column;

      .btn {
        @include responsiveFixedBottomBtn;
      }
    }

    .table-data {
      .row-th {
        justify-content: space-between;
        padding: 0 8px;

        .email,
        .created-date,
        .w10 {
          display: none;
        }

        .role {
          text-align: right;
        }
      }

      .row-td {
        display: flex;
        flex-wrap: wrap;
        padding: 16px 8px;

        .name {
          flex: 0 0 calc(100% - 80px);
          width: calc(100% - 80px);
        }

        .role {
          flex: 0 0 80px;
          width: 80px;
          text-align: right;
        }

        .email,
        .created-date {
          flex: 1 1 auto;
          width: 100%;

          .spacing {

            &,
            * {
              font-size: 14px;
              line-height: 1.5;
            }
          }
        }

        .manage {
          flex: 0 0 100%;
          width: 100%;
          margin-top: 8px;

          .blue-link {
            border: 1px solid #3241ff;
            border-radius: 16px;
            width: 100%;
            display: block;
            padding: 4px;
          }
        }
      }
    }
  }
}
