.modal-default.modal-message-details {
    .modal-mains {
        width: 1080px;
        height: auto;
        max-height: 80vh;
        padding: 40px 0;

        h3 {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
        }

        .inputs, .textarea {
            margin: 8px 0 24px;
        }
    }

    .modal-message-details-body {
        overflow: scroll;
        max-height: calc(80vh - 80px);
        padding: 0 40px;

        h2.top-title {
            margin-bottom: 8px;
        }

        .message-meta {
            &, * {
                font-size: 14px;
                color: rgba(117, 119, 153, 0.8);
            }
        }

        .message-body {
            white-space: pre-wrap;
        }
    }
}