@import '../../../styles/_colors-variables.scss';

// .suggestions-module
.suggestions-module {
    margin-bottom: 24px;
    .titles {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #121863;
        margin-bottom: 24px;
    }
    .suggestion-list {
        li {
            margin-bottom: 12px;
        }
        .txt {
            padding-left: 20px;
            font-weight: 400;
            font-size: 17px;
            line-height: 25px;
            letter-spacing: 0.005em;
            color: #121863;
            position: relative;
            a {
                color: rgba(50, 65, 255, 1);
                text-decoration: underline;
                font-weight: 700;
            }
            .points {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #3241FF;
                display: inline-block;
                margin-right: 5px;
                position: absolute;
                left: 5px;
                top: 12px;
            }
        }

        li.no-suggestion {
            .txt {
                padding-left: 0;
            }
        }
    }
    .show-all {
        padding: 20px 0 0;
        text-align: center;
        .btn-show,
        .btn-hide {
            font-size: 13px;
            line-height: 19px;
            text-align: right;
            color: #121863;
        }
        .btn-hide {
            .icon-drop {
                transform: rotateX(180deg);
            }
        }
        .icon-drop {
            background: url('/assets/icon-drop.svg') no-repeat center center;
            background-size: 10px 7px;
            width: 10px;
            height: 7px;
            margin-left: 5px;
            transform: rotateX(0);
        }
    }
}