#quickNavs {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 30px;
  top: 50px;
  padding: 16px;
  border: 2px solid lightgray;
  background: white;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  h3 {
    margin-top: 0;

    .menu-indicator {
      rotate: 90deg;

      &.open {
        rotate: 270deg;
      }
    }
  }

  a {
    display: block;
    max-width: 375px;
    text-align: center;

    + a {
      margin-top: 8px;
    }

    &.hidden {
      display: none !important;
    }
  }
}
