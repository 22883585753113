.mass-post-page {
  // Hack to stick the page to the top of the screen
  min-height: 80svh;

  .top-title-bar {
    padding-bottom: 34px;
    .left-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #121863;
    }
  }
}
