@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

// .collection-chart-area
.collection-chart-area {
  padding: 24px 32px 32px;
  gap: 8px;
  flex: 1;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  @media #{$smartphone-breakpoint} {
    @include spDefaultPadding;
  }

  .top-tools {
    .left-drop {
      .two-circle {
        .icons {
          width: 24px;
          height: 24px;
          margin-right: 7px;
          border-radius: 100%;
          &.disabled {
            cursor: not-allowed;
            opacity: 0.3;
            pointer-events: none;
          }
          &.up-icon {
            background: #f2947f url(/assets/white-drop.svg) no-repeat center;
            background-size: 11px 6px;
            transform: rotate(-180deg);
          }
          &.down-icon {
            background: #f2947f url(/assets/white-drop.svg) no-repeat center;
            background-size: 11px 6px;
          }
        }
      }
    }
    .right-btn {
      .btn {
        min-width: 120px;
        margin-left: 15px;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.015em;
        color: #3241ff;
        line-height: 35px;
        .icons {
          width: 24px;
          height: 24px;
          margin-right: 7px;
        }
        .icon-copy {
          background: url(/assets/content_copy.svg) no-repeat center;
          background-size: 24px 24px;
        }
        .icon-edit {
          background: url(/assets/blue-edit.svg) no-repeat center;
          background-size: 22px 22px;
          margin-top: -3px;
        }
        .icon-saveload-blue {
          background: url(/assets/saveload-blue.svg) no-repeat center;
          background-size: 22px 22px;
          margin-top: -3px;
        }
      }
    }
  }
  .chart-titles {
    padding: 8px 0;
    font-size: 24px;
    line-height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: -0.005em;
    color: #121863;
    font-weight: 700;

    @media #{$smartphone-breakpoint} {
      flex-direction: column;
      gap: 8px;
      text-align: center;
    }
  }
  .btn-filter {
    background: rgba(50, 65, 255, 0.05);
    border-radius: 8px;
    padding: 1px 15px;
    height: 40px;
    line-height: 38px;
    color: #3241ff;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    width: fit-content;
    .icon-filter {
      background: url("/assets/icon-filter.svg") center center no-repeat;
      background-size: 21px 20px;
      width: 21px;
      height: 20px;
      margin-right: 5px;
    }
  }
  .filter-wrap {
    position: relative;
    .filter-some-block {
      background-color: transparent;
      display: flex;
      align-items: flex-start;
      padding: 5px 16px 12px 0;
      justify-content: space-between;
      .after-info {
        display: none;
        margin-left: 22px;
        margin-top: 8px;
        align-items: flex-start;
        flex: 1;
        justify-content: space-between;
        .label-area {
          margin-right: 22px;
        }
        .filter-label-list {
          display: flex;
          align-items: flex-start;
          font-size: 11px;
          margin-bottom: 7px;
          .left-txt {
            margin-right: 4px;
            white-space: nowrap;
          }
        }
        .btn-border {
          height: 24px;
          line-height: 20px;
          font-size: 12px;
          padding: 1px 12px;
          white-space: nowrap;
        }
      }
    }
    &.filter-after {
      border-radius: 8px;
      margin-top: 15px;
      background-color: rgba(50, 65, 255, 0.05);
      .btn-filter {
        background-color: transparent;
        // pointer-events: none;
        height: auto;
        line-height: initial;
        .icon-filter {
          background: url("/assets/filter-blue.svg") center center no-repeat;
          background-size: 24px 24px;
        }
      }
      .after-info {
        display: flex;
      }
    }
  }
  .date-bar {
    margin-bottom: 24px;
    .temlplate-txt {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.015em;
      color: #757799;
    }

    @media #{$smartphone-breakpoint} {
      flex-direction: column;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
    }

    &:not(:has(.temlplate-txt)) {
      justify-content: flex-end;
    }
  }
  .chart-area {
    margin-bottom: 40px;
  }
  .points-area {
    .title-line {
      .left-txt {
        padding-right: 30px;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #121863;
      }
      .line {
        background: #c9d2df;
        flex: 1;
        height: 2px;
      }
    }
    .point-list {
      padding-top: 16px;
      .items {
        padding-left: 23px;
        margin-bottom: 12px;
        position: relative;
        .points {
          width: 5px;
          height: 5px;
          display: inline-block;
          position: absolute;
          left: 8px;
          top: 11px;
          border-radius: 100%;
          background-color: #121863;
        }
        .txt {
          font-weight: 400;
          font-size: 17px;
          line-height: 25px;
          letter-spacing: 0.005em;
          color: #121863;
        }
      }
    }
  }
}
