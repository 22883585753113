.instagram-post-preview {
  max-width: 432px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  gap: 8px;

  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  padding: 16px;
  border-radius: 8px;

  width: fit-content;

  .title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .left-side {
      color: gray;
      .account-name {
        color: black;
        font-weight: bold;
      }
      .following {
        font-weight: bold;
        color: #109bf6;
      }
    }

    .options {
      width: 30px;
      height: 30px;
      background: url("/assets/vertical-dots.svg") center center no-repeat;
      background-size: 30px auto;
      cursor: pointer;
      rotate: 90deg;
    }
  }

  .image-wrapper {
    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 300px;
    }
  }

  .buttons-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .instagram-post-preview-icons {
      width: 30px;
      height: 30px;
      background-size: 30px auto;
      cursor: pointer;
    }
    .left-side {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      .like {
        background-image: url("/assets/instagram/heart.svg");
      }
      .comment {
        background-image: url("/assets/instagram/comment.svg");
      }
      .share {
        background-image: url("/assets/instagram/share.svg");
      }
    }
    .bookmark {
      background-image: url("/assets/instagram/bookmark.svg");
    }
  }

  .text-wrapper {
    .account-name {
      font-weight: bold;
      word-break: break-all;
    }

    .summary {
      white-space: pre-wrap;
    }
  }

  .add-a-comment-prompt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-weight: light;
    color: grey;
    .emoji {
      width: 16px;
      height: 16px;
      background: url("/assets/instagram/emoji.svg") center center no-repeat;
      background-size: 16px auto;
      cursor: pointer;
    }
  }

  .bottom-border {
    height: 12px;
    width: 100%;
    border-bottom: solid 1px lightgrey;
  }
}
