@import "../../styles/responsive-variables";

.content-wrap.flex {
  align-items: stretch;
}

@media #{$tablet-breakpoint} {
  .left-nav {
    display: none;
  }
  .content-wrap.flex {
    flex-direction: column;
    min-height: calc(100vh - 64px);
  }

  .right-content {
    max-width: 100%;
    overflow: scroll;
  }
}

@media not screen and (max-width: 990px) {
  .bottom-nav {
    display: none;
  }
}

@media (min-width: 990px) and (max-width: 1279px) {
  .content-wrap {
    .left-nav {
      flex: 0 0 84px;
      max-width: 84px;
    }
    .right-content {
      max-width: calc(100% - 84px);
    }
  }
}

/* fix layout issue when smaller than 1280px width */
@media (min-width: 1280px) {
  // .content-wrap
  .content-wrap {
    .left-nav {
      flex: 0 0 207px;
      max-width: 207px;
      .top-arrow {
        transform: rotate(-180deg);
      }
      .nav-items {
        &.current {
          .txt {
            color: #fff;
          }
        }
        .icons {
          margin-right: 5px;
        }
        .txt {
          display: block;
        }
      }
    }

    .right-content {
      max-width: calc(100% - 207px);
    }
  }
}

small.data-note {
  font-size: 0.7em;

  @media #{$smartphone-breakpoint} {
    font-size: 0.85em;
  }
}
