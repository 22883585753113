@import '../../../../styles/_colors-variables.scss';

// .collection-chart-area-table-chart
.collection-chart-area-table-chart {
    padding-bottom: 75px;
    padding-top: 89px;
    // .blue-table-chart
    .blue-table-chart {
        width: 983px;
        margin: 0 auto;
        overflow-x: auto;
        .row-td,
        .row-th {
            width: 100%;
            display: table-row;
            table-layout: fixed;
        }
        .col-th {
            background-color: rgba(50, 65, 255, 1);
            color: #fff;
            font-family: 'Noto Sans JP';
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.015em;
            height: 34px;
            text-align: right;
            &:last-child {
                text-align: center;
            }
        }
        .col-th,
        .col-td {
            display: table-cell;
            vertical-align: middle;
            padding: 0 16px;
        }
        .row-td {
            &:nth-child(odd) {
                background-color: rgba(117, 119, 153, 0.1);
            }
            &:nth-child(even) {
                background-color: #fff;
            }
            &.total-row {
                .col-td {
                    background-color: rgba(50, 65, 255, 0.1);
                    font-weight: 700;
                    height: 41px;
                    &:first-child {
                        color: #121863;
                    }
                }
            }
        }
        .col-td {
            height: 49px;
            font-weight: 400;
            font-size: 17px;
            line-height: 25px;
            text-align: right;
            letter-spacing: 0.005em;
            color: #121863;
            &:first-child {
                color: #fff;
                background-color: rgba(50, 65, 255, 0.6);
                text-align: left;
                font-size: 14px;
                font-weight: 700;
            }
            &:last-child {
                font-weight: 700;
                font-size: 17px;
                line-height: 25px;
                text-align: right;
                letter-spacing: 0.0015em;
                color: #121863;
                background-color: rgba(50, 65, 255, 0.1);
                padding: 0 20px;
            }
        }
    }
}