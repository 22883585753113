@import "../../../styles/responsive-variables";

.current-filters {
  display: none;
  margin-top: 16px;
  padding: 16px;
  background: rgba(50, 65, 255, 0.05);
  border-radius: 8px;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
  min-width: 85%;

  .current-filters-top {
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 8px;

    h3 {
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.015em;
    }
  }

  .label-area {
    margin-right: 22px;
    font-size: 11px;
    display: grid;
    grid-template-columns: minmax(auto, 250px) auto;
    grid-gap: 4px 8px;
    flex: 1 1 auto;

    .left-txt {
      margin-right: 4px;
      white-space: nowrap;
    }
  }

  .btn-border {
    height: 24px;
    line-height: 20px;
    font-size: 12px;
    padding: 1px 12px;
    white-space: nowrap;
  }

  @media #{$smartphone-breakpoint} {
    .label-area {
      grid-template-columns: 1fr;
      grid-gap: 0;

      .left-txt {
        margin-top: 8px;
        font-weight: 700;
      }
    }
  }
}
