.local-post-preview-offer-coupon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #fafafa;
  border-radius: 8px;
  border-style: dashed;
  border-color: #eeeeee;
  padding: 16px;
  margin: 0 8px;

  .please-show {
    color: #606060;
  }

  .coupon-code {
    color: black;
    font-size: xx-large;
  }

  .schedule {
    color: black;
  }
}
