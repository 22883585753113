@import '../../../styles/_colors-variables.scss';

// .suggestions-table-module
.suggestions-table-module {
    margin-bottom: 24px;
    .titles {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #121863;
    }
    .group-wrap {
        margin-top: 24px;
        margin-bottom: 10px;
        .group-title {
            margin-bottom: 16px;
            .blue-block {
                padding: 2px 15px;
                min-width: 49px;
                line-height: 29px;
                display: inline-block;
                background: #121863;
                border-radius: 4px;
                color: #fff;
                margin-right: 12px;
                font-weight: 700;
                font-size: 17px;
                line-height: 25px;
                text-align: center;
            }
            .title {
                font-weight: 700;
                font-size: 17px;
                line-height: 25px;
                color: #121863;
            }
            .btn-border {
                min-width: 59px;
                line-height: 20px;
                height: 24px;
                font-weight: 700;
                font-size: 12px;
            }
            .email-send {
                .orange-done {
                    width: 24px;
                    height: 24px;
                    background: url(/assets/check_circle.svg);
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 5px;
                    flex: 0 0 24px;
                    max-width: 24px;
                }
                .txt {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.015em;
                    color: #F2947F;
                }
            }
        }
        .txt-area {
            font-weight: 400;
            font-size: 17px;
            line-height: 25px;
            color: #121863;
        }

        + .group-wrap {
            margin-top: 48px;
        }
    }
    .button-container {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
}