@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

// .modal-facility-selection
.modal-default.modal-facility-selection {
  background-color: rgba(0, 0, 0, 0.2);
  .modal-mains {
    margin: 500px auto 50px;
    width: 1080px;
    height: auto;
    padding: 40px;
    .btn-close {
      width: 32px;
      height: 32px;
      &:hover {
        opacity: 0.9;
      }
    }
    .filter-bar {
      padding: 15px 0 24px;
    }
    .btn-filter {
      background: rgba(50, 65, 255, 0.05);
      border-radius: 8px;
      padding: 1px 15px;
      height: 40px;
      line-height: 38px;
      color: #3241ff;
      font-weight: 700;
      font-size: 16px;
      display: flex;
      align-items: center;
      .icon-filter {
        background: url("/assets/icon-filter.svg") center center no-repeat;
        background-size: 21px 20px;
        width: 21px;
        height: 20px;
        margin-right: 5px;
      }
    }
    .content-selection-wrap {
      padding: 0 15px;
      overflow-x: auto;
      margin: 0 -40px 0 -10px;
      &::-webkit-scrollbar {
        height: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(201, 210, 223, 0.4);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background-color: #c9d2df;
      }

      &::-webkit-scrollbar-button {
        background-color: transparent;
      }

      &::-webkit-scrollbar-corner {
        background-color: black;
      }
      .row {
        flex-wrap: nowrap;
      }
      .lists {
        li {
          margin-bottom: 24px;
        }
      }
    }
    .registered-list {
      padding: 24px 0 24px;
      .groups {
        margin-bottom: 24px;
        .color-block {
          display: inline-flex;
          align-items: center;
          padding: 12px;
          border-radius: 8px;
          font-weight: 400;
          font-size: 17px;
          line-height: 25px;
          color: #121863;
          margin-bottom: 12px;
          &.pink {
            background: rgba(219, 48, 63, 0.05);
          }
          &.blue {
            background: rgba(50, 65, 255, 0.05);
          }
          .number {
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            color: #121863;
            margin-left: 12px;
          }
        }
        .p-txt {
          font-weight: 700;
          font-size: 12px;
          line-height: 17px;
          color: #121863;
        }
      }
    }
    .bottom-btns {
      margin-top: 0;
    }
  }

  @media #{$smartphone-breakpoint} {
    padding: 24px 16px 60px;
    .modal-mains {
      @include spDefaultPadding;
      margin: auto;

      .top-title {
        margin-bottom: 0;
      }

      .filter-bar {
        padding: 15px 0 24px;
      }
      .content-selection-wrap {
        padding: 0;
        margin: 0;
        .row {
          flex-direction: column;
          width: 100%;
          margin: 0;

          .col {
            padding: 0;
            ul.lists {
              li {
                margin-bottom: 12px;
              }
            }
          }
        }
      }
      .registered-list {
        padding: 24px 0 24px;

        .groups {
          .color-block {
            padding: 4px 8px;
            font-size: 14px;
            .number {
              font-size: $sp-title-size;
            }
          }
        }
      }
      .bottom-btns {
        margin-top: 0;
      }
    }
  }
}
