.examplePost {
  max-width: 540px;
  width: 100%;
  border: 1px solid #dadce0;
  border-radius: 8px;
  overflow: hidden;

  * {
    color: #3c4043;
  }

  p {
    font-size: 14px;
    margin: 0;
  }

  &__head {
    position: relative;

    p {
      padding: 16px 0 0 16px;
      color: #5f6368;
    }
    :has(img)::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 120px;
      background: linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0));
    }
    > img {
      display: block;
      width: 100%;
      height: auto;

      + p {
        color: #ccc;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

  }
  &__body {
    padding: 16px;
    &__header {
      margin-bottom: 16px;

      h3 {
        margin: 0;
        font-weight: 400 !important;
      }

      .date {
        font-size: 12px;
        color: #5f6368;
      }
    }

    &__summary {
      white-space: pre-line;
    }

    > a {
      margin-top: 16px;
      display: inline-flex;
      align-items: center;
      height: 40px;
      padding: 0 24px;
      border-radius: 8px;
      color: white;
      background: #1a73e8;
      text-decoration: none !important;
    }
  }
}
