@import '../../../styles/_colors-variables.scss';

/* .radio-wrap */
.radio-wrap {
    display: inline-block;
    vertical-align: middle;
    margin-right: 32px;
    align-items: center;
    input[type="radio"]{
        display: none;
    }
    input[type="radio"]+label {
        color: #373a3c;
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        display: flex;
        font-weight: normal;
        align-items: center;
        padding-left: 24px;
        text-align: left;
        position: relative;
        text-transform: none;
        cursor: pointer;
        margin-right: 8px;
        .gray-text {
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #757799;
        }
    }
    label::before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 6px;
        position: absolute;
        left: 0;
        top: 5px;
        border-radius: 100%;
        border: 1px solid #757799;
    }
    input[type="radio"]:checked+label::before{
        border: 4px solid #3241FF;
    }
    .gray-text {
        font-size: 11px;
        color: #757799;
        margin-left: 7px;
        margin-top: 5px;
    }
}