@import '../../../styles/_colors-variables.scss';

// .modal-facility-selection-confirm
.modal-default.modal-facility-selection-confirm {
    background-color: rgba(0,0,0,0.2);
    .modal-mains {
        width: 600px;
        height: auto;
        padding-bottom: 60px;
        .btn-close {
            width: 33px;
            height: 33px;
            &:hover {
                opacity: 0.9;
            }
        }
        .p-txt {
            font-weight: 400;
            font-size: 17px;
            line-height: 25px;
            color: #121863;
            margin-bottom: 24px;
        }
        .list-border-box {
            margin: 0 20px;
            border: 1px solid #C9D2DF;
            border-radius: 8px;
            padding: 16px 24px;
            li {
                font-weight: 700;
                font-size: 17px;
                line-height: 25px;
                color: #121863;
            }
        }
        .bottom-btns {
            .btn {
                min-width: 220px;
            }
        }
    }
}