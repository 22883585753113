.input-text-area-chars-display {
  display: flex;
  flex-direction: row;
  gap: 0.5em;

  position: absolute;
  top: 100%;
  right: 0;

  font-size: 12px;

  &.over > span {
    color: red;
  }

  @media screen and (max-width: 400px) {
    display: none;
  }
}
