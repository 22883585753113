.mediaExamples {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(3, 250px);

  &__item {
    position: relative;
    height: 250px;

    > img {
      max-width: 100%;
      height: 250px;
      object-fit: cover;
    }

    p {
      position: absolute;
      color: white;
      text-shadow: 0 0 3px black;
      margin: 0;
      background: rgba(0,0,0,0.1);
    }

    &__cat {
      top: 12px;
      right: 12px;
    }

    &__viewCount {
      bottom: 12px;
      left: 12px;
    }

    &__date {
      top: 12px;
      left: 12px;
    }
  }
}
