@import "../../styles/_colors-variables.scss";

.modal-default {
  &.modal-privacy-policy-and-terms-service {
    display: flex;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.2);
    .modal-mains {
      height: auto;
      width: 90vw;
      max-width: 1080px;
      max-height: 80vh;
      margin: auto;
      .btn-close {
        width: 33px;
        height: 33px;
        &:hover {
          opacity: 0.9;
        }
      }
      .bottom-btns {
        margin-top: 35px;
      }
      .content {
        white-space: break-spaces;
        font-size: 17px;
        line-height: 27px;
        letter-spacing: 0.005em;
        color: #121863;
        max-height: calc(80vh - 265px);
        overflow-y: auto;
        padding-right: 16px;
        strong {
          line-height: 40px;
        }
        ul {
          li {
            list-style: inside;
          }
        }
        a {
          text-decoration: underline;
        }
        .red-txt {
          color: #f00;
        }
      }
    }
  }
}
