@import "../../../../../../styles/responsive-variables";

.monthSection {
  & + & {
    margin-top: 30px;
  }

  .line-container {
    max-width: 1000px;
    height: 400px;

    @media #{$smartphone-breakpoint} {
      height: 300px;
    }
  }
}
