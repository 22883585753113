.sign-in-with-google {
  height: 40px;
  border-radius: 4px;
  margin: 0 16px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  background: #ffffff;
  outline: none;
  border: 1px solid #bebebe;

  span {
    font-family: 'Roboto';
    line-height: 20px;
    font-size: 16px;
    margin-left: 12px;
    color: #757575;
  }
  &:hover {
    background: #ffffff;
  }

  &:disabled {
    background: #dcdcdc;
    span {
      color: #a0a0a0
    }
  }
}

.icon-google {
  background: url('/assets/google.svg') center center no-repeat;
  background-size: 18px 18px;
  width: 20px;
  height: 20px;
}
