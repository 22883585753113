@import '../../../styles/_colors-variables.scss';

// .white-border-panel 
.white-border-panel {
  &.dashboard-important-metrics-module {
    padding: 32px 24px;
  }
}

// .dashboard-important-metrics-module
.dashboard-important-metrics-module {
    margin-bottom: 24px;
    .panel-titles {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #121863;
        margin-bottom: 16px;
    }
    .three-border-box {
        padding: 0 32px;
        .row {
            margin: 0 -20px;
            .col {
                padding: 0 20px;
            }
        }
        .border-box {
            border: 1px solid #C9D2DF;
            border-radius: 16px;
            padding: 16px 5px 16px 40px;
            .top-little {
                margin-bottom: 16px;
                .little {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.015em;
                    color: #121863;
                }
                .title {
                    font-weight: 700;
                    font-size: 17px;
                    line-height: 25px;
                    letter-spacing: 0.0015em;
                    color: #121863;
                }
            }
            .parent-area {
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                word-break: break-all;
                .icon-up-down {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;
                    margin-bottom: 8px;
                    flex: 0 0 32px;
                    max-width: 32px;
                    &.even {
                        background: url('/assets/arrow-even.svg') center center no-repeat;
                        background-size: 32px 32px;
                    }
                    &.up {
                        background: url('/assets/arrow-up.svg') center center no-repeat;
                        background-size: 32px 32px;
                    }
                    &.down {
                        background: url('/assets/arrow-down.svg') center center no-repeat;
                        background-size: 32px 32px;
                    }
                }
                .parent-num {
                    font-family: 'Roboto';
                    font-weight: 600;
                    font-size: 48px;
                    line-height: 53px;
                    color: #121863;
                    display: flex;
                    align-items: flex-end;
                    margin-right: 5px;
                    .little {
                        font-size: 24px;
                        display: inline-block;
                        vertical-align: bottom;
                        margin-bottom: 3px;
                        line-height: 32px;
                    }
                }
                .gray-txt {
                    font-family: 'Noto Sans JP';
                    font-size: 11px;
                    line-height: 16px;
                    letter-spacing: 0.01em;
                    color: #757799;
                    // max-width: 56px;
                    margin-bottom: 4px;
                }
            }
        }
    }
}