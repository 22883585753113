@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

.pickupFields-module {
  margin-top: 32px;

  .top-bar {
    margin-bottom: 30px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #121863;
    }
    .rights {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: #121863;
      position: relative;
      font-family: "Roboto";
      .letter-square {
        margin-left: 8px;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 0 40px;

    .groups {
      margin: 30px 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      .gray-top {
        .txt {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.015em;
          color: #757799;
        }
        .icon-question {
          background: url("/assets/question.svg") no-repeat center center;
          background-size: 16px 16px;
          margin-left: 4px;
          width: 16px;
          height: 16px;
          display: inline-block;
        }
      }
      .color-txt {
        margin: 20px 0;
        flex: 1 1 auto;
        .icon-done {
          background: rgba(122, 198, 63, 0.3) url("/assets/green-done.svg")
            no-repeat center center;
          background-size: 29px 29px;
          margin-left: 4px;
          width: 28px;
          height: 28px;
          margin-right: 8px;
          border-radius: 100%;
          &.disabled {
            background: rgba(220, 91, 62, 0.3) url("/assets/red-not-done.svg")
              no-repeat center center;
            background-size: 29px 29px;
          }
        }

        .icon-error {
          background: url(/assets/error.svg) center center no-repeat;
          background-size: 28px;
          width: 28px;
          height: 28px;
          margin-bottom: -6px;
          margin-right: 4px;
        }
        .txt {
          display: inline-block;
          vertical-align: bottom;
          font-weight: 700;
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.015em;
          color: #121863;
          &.big-txt {
            font-family: "Roboto";
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            margin-right: 4px;
            margin-bottom: -7px;
          }
          &.parent-txt {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
      .btn-footnote {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        color: #757799;
        min-height: 24px;
      }
    }
  }

  @media #{$smartphone-breakpoint} {
    .top-bar {
      .title {
        flex-direction: column;
        align-items: flex-start;
      }
      margin-bottom: 16px;
    }

    &__body {
      grid-template-columns: 1fr 1fr;
      grid-gap: 32px 16px;

      .groups {
        margin: 0;

        .pink-tips.top {
          transform: translateX(-65%);
        }

        .color-txt {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          margin: 8px;
        }
      }
    }
  }
}
