.custom-archive {
  .rights {
    float: right;
    .icon-del {
        background: url(/assets/blue-del.svg) center no-repeat;
        background-size: 18px 24px;
        width: 18px;
        height: 24px;
        margin-left: 32px;
    }        
  }
  .titles + .inputs {
    margin-top: 8px;
  }

  .filter-mains .check-full-wrap {
    height: calc(100% - 224px);
  }
}