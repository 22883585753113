@import "../../../styles/_responsive-variables.scss";

.reviewSummary-module {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: stretch;
  gap: 16px;

  @media #{$smartphone-breakpoint} {
    flex-direction: column;
    align-items: center;
  }

  .review-summary-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #121863;

    @media #{$smartphone-breakpoint} {
      flex-direction: column;
    }
  }

  .rights {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #121863;
    position: relative;
    font-family: "Roboto";
    .letter-square {
      margin-left: 8px;
    }
  }

  .content {
    display: flex;
    flex-grow: 1;
    gap: 16px;
    padding: 16px 0;
    @media #{$tablet-breakpoint} {
      flex-direction: column;
      align-items: center;
    }

    .summary-container {
      max-width: 640px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 32px;

      @media #{$tablet-breakpoint} {
        align-items: center;
      }

      .summary {
        white-space: pre-wrap;
      }
    }
  }

  .qr-code-container {
    padding-left: 16px;
    border-left: 4px solid #c9d2df;

    @media #{$smartphone-breakpoint} {
      padding-left: 0;
      border-left: none;
      padding-top: 16px;
      border-top: 4px solid #c9d2df;
    }
  }
}
