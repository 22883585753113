@import '../../styles/_colors-variables.scss';

.account-management {
    // .top-title-bar
    .top-title-bar {
        padding-bottom: 34px;
        flex-wrap: wrap;
        .left-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 130%;
            color: #121863;
        }
    }
}