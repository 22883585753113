@import '../../styles/_colors-variables.scss';
.mass-send {
  .top-title-bar {
    padding-bottom: 34px;
    flex-wrap: wrap;
    .left-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
        color: #121863;
    }
  }

  &__main {
    h2 {
      margin-top: 1em;
    }
    h3 {
      margin: 1em  0 0.5em;
      font-size: 1.5rem;
    }

    &__form {
      position: relative;
      padding-bottom: 30px;

      &__checkbox {
        input[type="checkbox"] {
          margin-right: 8px;
        }
      }
      &__btns {
        margin-top: 2em;
      }

      .error {
        padding: 8px 0 0 5px;
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: 0.015em;
        color: #C70000;
        position: absolute;
        bottom: 8px;
        left: 16px;
        &:before {
            content: '';
            display: block;
            background: url(/assets/warning.svg) center center no-repeat;
            background-size: 15px 13px;
            width: 15px;
            height: 15px;
            position: absolute;
            left: -15px;
            top: 10px;
        }
    }
    }
  }
}