@import '../../../styles/_colors-variables.scss';

// .collections-filter-bar
.collections-filter-bar {
    .update-wrap {
        .line-bar {
            width: 100%;
            height: 3px;
            background: #C9D2DF;
            margin: 8px 0 48px;
            position: relative;
        }
        .center-area {
            background-color: #FBFBFF;
            padding: 0 24px;
            display: flex;
            align-items: center;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -17px;
            max-width: 770px;
            .left-date {
                font-weight: 700;
                font-size: 24px;
                line-height: 35px;
                display: flex;
                align-items: center;
                color: #121863;
                margin-right: 16px;
                max-width: 600px;
                
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .right-dropdown {
                flex: 0 0 auto;
                max-width: fit-content;
                .save-load-wrap {
                    position: relative;
                    &.open {
                        .saveload-panel {
                            display: block;
                        }
                    }
                    .blue-block {
                        background: #121863;
                        border-radius: 40px;
                        min-width: 135px;
                        height: 32px;
                        line-height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 150%;
                        display: flex;
                        align-items: center;
                        color: #FFFFFF;
                        .icon-download {
                            background: url(/assets/saveload.svg) no-repeat center;
                            background-size: 24px 24px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .saveload-panel {
                        display: none;
                        position: absolute;
                        top: 40px;
                        left: -60px;
                        width: 328px;
                        // height: 279px;
                        background: #FFFFFF;
                        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
                        border-radius: 16px;
                        padding: 32px 24px;
                        z-index: 1;
                        .txt {
                            font-family: 'Noto Sans JP';
                            font-weight: 400;
                            font-size: 17px;
                            line-height: 25px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 12px;
                        }
                        .btn-wrap {
                            .btn {
                                width: 100%;
                                height: 40px;
                                margin-bottom: 16px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}