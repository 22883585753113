$base-url: '../../assets/fonts/';

// Roboto
@font-face {
	font-family: 'Roboto'; 
	src: URL($base-url + 'RobotoRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Roboto'; 
	src: URL($base-url + 'RobotoMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
	font-family: 'Roboto'; 
	src: URL($base-url + 'RobotoBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

// Mulish
@font-face {
	font-family: 'Mulish'; 
	src: URL($base-url + 'Mulish-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Mulish'; 
	src: URL($base-url + 'Mulish-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
	font-family: 'Mulish'; 
	src: URL($base-url + 'Mulish-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

// Noto Sans JP
@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
	font-family: 'Noto Sans JP'; 
	src: URL($base-url + 'NotoSansJP-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}