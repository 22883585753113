$themeBlue: #3241FF;
$textColor: rgba(18, 24, 99, 1);

@mixin buttonStyle {
  display: inline-block;
  background: $themeBlue;
  color: white;
  border-radius: 100px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;
  border: 1px solid transparent;
  transition: 0.5s;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  border: none;
  box-shadow: none;
  position: relative;
  text-transform: none;
  cursor: pointer;

  &:not(.disabled):hover {
    border: 1px solid $themeBlue;
    background: white;
    color: $themeBlue;
  }
}

.org-tool {
  display: flex;
  flex-direction: column;
  flex: auto;

  .top-title-bar {
    padding-bottom: 34px;
    flex-wrap: wrap;
    .left-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
        color: #121863;
    }
  }

  .internal-tool-tools {
    position: relative;
  }

  * {
    font-family: 'Noto Sans JP', sans-serif;
    color: $textColor;
  }

  h1 {
    margin: 25px 0px;
    font-size: 32px;
    line-height: 130%;
    letter-spacing: -0.015em;
  }

  h2 {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: -0.005em;

    &::after {
      content: '';
      position: relative;
      top: -3px;
      flex: 1 1 auto;
      height: 3px;
      margin: 32px 40px 16px 24px;
      background: #D7DDE7;
    }

    &.big {
      font-size: 36px;
    }
  }

  p {
    display: block;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  > section {
    width: 100%;
  }

  section.card {
    margin-right: 24px;
    background: white;
    border: 1px solid #D7DDE7;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 24px 32px 32px 32px;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    }

    > h3, > p {
      &:first-child {
        margin-top: 0;
      }
    }

    & + & {
      margin-top: 40px;
    }

    button {
      @include buttonStyle;
      height: 40px;
      margin-top: 24px;
      border: 0;
      padding: 0 24px;
      min-width: 150px;
    }

    input {
      border: 1px solid #C4C4C4;
      border-radius: 8px;
      padding: 16px 9px;
      color: $themeBlue;
      margin-right: 16px;
      width: fit-content;
    }

    a {
      text-decoration: underline;
    }

    #locationName, #extraKeywords {
      width: fit-content;
    }

    #error {
      white-space: break-spaces;
      color: red;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;

      &.showError {
         + #error-retry {
          display: block;

          button {
            margin-top: 0;
          }
         }
      }
    }
  }

  #quickNavs {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    right: 30px;
    top: 50px;
    padding: 16px;
    border: 2px solid lightgray;
    background: white;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    h3 {
      margin-top: 0;
    }

    a {
      display: block;
      max-width: 375px;
      text-align: center;

      + a {
        margin-top: 8px;
      }
    }
  }
}