$tablet-breakpoint: "screen and (max-width: 990px)";
$smartphone-breakpoint: "screen and (max-width: 767px)";

$sp-title-size: 24px;
$sp-h2-size: 21px;
$sp-main-text-size: 15px;
$sp-input-size: 16px;

@mixin spDefaultPadding {
  padding: 16px;
}

@mixin responsiveBottomBtns {
  .bottom-btns {
    justify-content: space-between;
    .btn {
      margin: 0;
      min-width: 0;
      flex: 0 0 calc(50% - 10px);
      box-sizing: border-box;
    }
  }
}

@mixin responsiveFixedBottomBtn {
  position: fixed;
  bottom: 72px;
  left: 50%;
  min-width: 200px;
  transform: translate(-50%, 0);
  z-index: 10;
}
