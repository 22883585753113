@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

.list-top-bar {
  padding: 0 20px 25px;
  &.flex-grid {
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .lefts {
    flex: 1;
    margin-right: 20px;
    .btn-filter {
      background: rgba(50, 65, 255, 0.05);
      border-radius: 8px;
      padding: 1px 15px;
      height: 40px;
      line-height: 38px;
      color: #3241ff;
      font-weight: 700;
      font-size: 16px;
      display: flex;
      align-items: center;
      .icon-filter {
        background: url("/assets/icon-filter.svg") center center no-repeat;
        background-size: 21px 20px;
        width: 21px;
        height: 20px;
        margin-right: 5px;
      }
    }
    .filter-wrap {
      position: relative;
    }
  }
  .rights {
    .search-module {
      margin-right: 32px;
    }
    .top-txt {
      margin-top: -7px;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: #757799;
      margin-bottom: 5px;
    }
  }


  @media #{$smartphone-breakpoint} {
    padding: 0px 16px 24px;
    .lefts {
      margin-right: 0;
      .filter-wrap {
        .filter-some-block {
          justify-content: flex-end;
          padding-right: 0;
        }
      }
    }
  }
}
