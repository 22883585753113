.function-management.filter-panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.2);

  .filter-mains {
    background: #fbfbff;
    position: absolute;
    width: 900px;
    height: fit-content;
    max-height: 90%;
    top: 106px;
    left: calc(50% - 450px);
    right: 100px;
    bottom: 35px;
    padding: 32px 40px 37px;
    border-radius: 16px;

    .check-full-wrap {
      overflow-y: auto;
      height: calc(100% - 114px);
      position: relative;
    }

    .bottom-btns {
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        min-width: 160px;
        margin: 0 8px;
      }
    }
  }
}
