.summaryLocationList {
  margin-top: 16px;
  padding: 16px;
  background: rgba(50, 65, 255, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .clear-all-button-wrapper {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
  }

  .locations {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px 32px;
    max-height: 240px;
    overflow: scroll;
  }

  span {
    font-size: 12px;

    &.empty {
      grid-column: 1 / span 4;
      text-align: center;
      font-weight: bold;
    }
  }

  &-footnote {
    display: block;
    margin-top: 12px;
    font-size: 12px;
    color: rgba(117, 119, 153, 0.7);
  }
}
