@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

/* .inputs */
.textarea {
  padding: 16px;
  display: block;
  position: relative;
  border: 1px solid #c9d2df;
  border-radius: 8px;
  min-height: 120px;
  height: auto;

  textarea {
    font-size: 17px;
    color: var(--text-color);
    background: none;
    border: none;
    width: 100%;
    height: 100%;
    min-height: 60px;
    margin: 0;
    padding: 0;
    line-height: 18px;
    vertical-align: middle;
    box-shadow: none;
    resize: none;
    outline: none;
    background-color: transparent;
    white-space: pre-wrap;
  }
  textarea::-ms-clear,
  textarea::-ms-reveal {
    display: none;
  }
  textarea::-webkit-input-placeholder {
    color: rgba(117, 119, 153, 0.7);
  }
  textarea:-moz-placeholder {
    color: rgba(117, 119, 153, 0.7);
  }
  textarea::-moz-placeholder {
    color: rgba(117, 119, 153, 0.7);
  }
  textarea:-ms-input-placeholder {
    color: rgba(117, 119, 153, 0.7);
  }
  &.error-txt {
    border: 1px solid #c70000;
  }

  .error {
    padding: 8px 0 0 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: #c70000;
    position: absolute;
    top: 100%;
    &:before {
      content: "";
      display: block;
      background: url(/assets/warning.svg) center center no-repeat;
      background-size: 15px 13px;
      width: 15px;
      height: 15px;
      position: absolute;
      left: -15px;
      top: 10px;
    }
  }

  @media #{$smartphone-breakpoint} {
    textarea {
      font-size: 13px;
    }
  }
}
