@import "../../../styles/_responsive-variables.scss";

.date-interval-wrapper {
  .inputs {
    margin: 0 !important;
  }
  display: flex;
  flex-direction: column;
  gap: 10px;
  .date-interval-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .label-txt,
    label {
      font-weight: bold;
      cursor: text;
    }
  }
  .error {
    padding: 8px 0 0 21px;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: #c70000;
    position: relative;
    &:before {
      content: "";
      display: block;
      background: url(/assets/warning.svg) center center no-repeat;
      background-size: 15px 13px;
      width: 15px;
      height: 15px;
      position: absolute;
      left: 0px;
      top: 10px;
    }
  }

  @media #{$smartphone-breakpoint} {
    margin: 10px 0;
    .date-interval-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;

      .date-interval-base-date-input,
      .time-input {
        gap: 4px;
      }

      .label-txt,
      label {
        font-size: 14px;
      }
    }
  }
}

.date-interval-base-date-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .label-txt {
    padding-left: 12px;
  }
}
