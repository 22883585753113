@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

// .white-border-panel
.white-border-panel {
  &.important-metrics-module {
    padding: 32px 24px;

    @media #{$smartphone-breakpoint} {
      @include spDefaultPadding;
    }
  }
}

.important-metrics-module {
  .titles {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #121863;
    margin-bottom: 24px;
  }

  .data-note {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .table-data {
    display: block;
    .row-th,
    .row-td {
      display: table;
      width: 100%;
      border-bottom: 0;
    }
    .row-th {
      background-color: transparent;
      .spacing {
        color: #757799;
        font-size: 14px;
      }
    }
    .row-td {
      &:nth-child(even) {
        background-color: rgba(242, 148, 127, 0.1);
      }
      &:nth-child(odd) {
        background-color: #fff;
      }
    }

    .width425 {
      width: 42.5%;
    }
    .width20 {
      width: 20%;
    }
    .width175 {
      width: 17.5%;
    }
    .col-th,
    .col-td {
      text-align: right;
      font-weight: 700;
      font-size: 17px;
      line-height: 25px;
      text-align: right;
      letter-spacing: 0.0015em;
      color: #121863;
    }
    .question-module {
      .icon-question {
        margin-left: 5px;
      }
      .pink-tips {
        left: -38px;
      }
    }

    .gray-txt {
      color: #757799;
      font-size: 14px;
    }
    .spacing {
      padding: 0 10px;
      word-break: break-word;
    }
    .txt {
      &.gray {
        color: #757799;
      }
      &.red {
        color: #dc5b3e;
      }
      &.green {
        color: #7ac63f;
      }
    }
  }

  @media #{$smartphone-breakpoint} {
    .table-data {
      .row-th,
      .row-td {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }

      .col-th,
      .col-td {
        font-size: 14px;

        .spacing,
        div.gray-txt.sp-only {
          text-align: center;
        }

        &.title {
          flex: 1 1 auto;
          width: 100%;
          margin-bottom: 8px;

          .spacing {
            text-align: center;
            line-height: 1;

            span.gray-txt {
              font-size: $sp-main-text-size;
            }

            .question-module {
              display: inline-block;
              margin-left: 4px;

              i.icon-question {
                margin: 0;
              }

              .triangle.top {
                top: -5px;
              }
            }
          }
        }

        &.numbers,
        &.perc-change {
          flex: 0 0 33.3%;
        }
      }
    }
  }
}
