@import "../../styles/_colors-variables.scss";

.letter-square {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #7ac63f;
  display: inline-block;
  border: 2px solid #7ac63f;
  border-radius: 4px;
  min-width: 24px;
  margin-right: 5px;
  text-align: center;
  &.yellow {
    color: #f7c30e;
    border: 2px solid #f6c30e;
  }
  &.red {
    color: white;
    background-color: #dc5b3e;
    border: 2px solid #dc5b3e;
    animation: fadeInOutC 10s;
    @keyframes fadeInOutC {
      0%,
      26%,
      52%,
      78% {
        color: #dc5b3e;
        background-color: transparent;
      }
      13%,
      39%,
      65%,
      100% {
        color: white;
        background-color: #dc5b3e;
      }
    }
  }
  &.brown {
    color: white;
    background-color: #96001b;
    border: 2px solid #96001b;
    animation: fadeInOutD 10s;
    @keyframes fadeInOutD {
      0%,
      12%,
      24%,
      36%,
      48%,
      60%,
      72%,
      84% {
        color: #96001b;
        background-color: transparent;
      }
      6%,
      18%,
      30%,
      42%,
      54%,
      66%,
      78%,
      100% {
        color: white;
        background-color: #96001b;
      }
    }
  }
}
