@import "../../../../styles/_responsive-variables.scss";

.local-post-item {
  width: 303px;
  max-height: 383px;
  height: fit-content;
  margin-right: 20px;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  .local-post-top-info {
    height: 60px;
    display: flex;
    width: inherit;
    gap: 5px;
    flex-direction: row;
    padding: 0 3px;
    justify-content: space-between;
    align-items: flex-end;

    .left-title {
      max-height: 60px;
      font-size: 18px;
      color: #121863;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .right-title {
      font-size: 16px;
      color: #121863;
      font-weight: 300;
      min-width: fit-content;
    }
  }

  .local-post-bottom-container {
    border: 2px solid #f5f7fa;
    border-radius: 10px;

    .local-post-img-container {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;

      .local-post-img {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 200px;
        background: #f5f7fa;
        object-fit: contain;
        background-color: #5a5a5b;
      }

      .no-image {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 200px;
        background-color: #ccc;
        text-align: center;
        color: #000;
        font-size: 30px;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .summary {
      font-size: 16px;
      color: #000;
      padding: 10px;
      height: 110px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    .no-summary {
      width: 100%;
      font-size: 16px;
      height: 110px;
      background-color: white;
      text-align: center;
      color: #000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  @media #{$smartphone-breakpoint} {
    width: 240px;

    .local-post-top-info {
      .right-title {
        font-size: $sp-main-text-size;
      }
    }

    .local-post-bottom-container {
      .local-post-img-container {
        .local-post-img {
          height: 150px;
        }
      }
      .summary,
      .no-summary {
        font-size: 14px;
        height: 96px;
      }
    }
  }
}
