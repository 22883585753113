@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

// .filter-panel
.filter-panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.2);
  .filter-mains {
    background: #fbfbff;
    position: absolute;
    top: 106px;
    left: 100px;
    right: 100px;
    bottom: 35px;
    padding: 32px 40px 37px;
    border-radius: 16px;
    .check-full-wrap {
      overflow-y: auto;
      height: calc(100% - 114px);
      position: relative;
    }
    .bottom-btns {
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        min-width: 160px;
        margin: 0 8px;
      }
    }
  }
  .btn-close {
    position: absolute;
    top: -16px;
    right: -16px;
    width: 32px;
    height: 32px;
    background: #121863 url("/assets/close.svg") center center no-repeat;
    background-size: 14px 14px;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 100%;
    opacity: 1;
    &:hover {
      opacity: 0.9;
    }
  }
  .top-title {
    padding: 0 0 24px;
    .titles {
      font-size: 24px;
      font-weight: 700;
      line-height: 35px;
      color: #121863;
    }
    .rights {
      display: flex;
      gap: 8px;
      .btn-border {
        min-width: 56px;
        height: 24px;
        line-height: 20px;
        font-size: 12px;
        padding: 1px 12px;
      }
    }
  }

  @media #{$smartphone-breakpoint} {
    .filter-mains {
      @include spDefaultPadding;
      top: 90px;
      bottom: 60px;
      left: 16px;
      right: 16px;

      .bottom-btns {
        margin-top: 24px;
        gap: 8px;

        .btn {
          min-width: 140px;
          margin: 0;
          flex: 1 1 auto;
        }
      }
    }
  }
}
