@import "../../styles/_colors-variables.scss";

.modal-default {
  &.modal-please-wait {
    .modal-mains {
      width: 600px !important;
      height: auto;
      padding: 32px 40px !important;
      .description {
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        margin: 50px 0;
        white-space: break-spaces;
      }
    }
    .bottom-btns {
      gap: 8px;
    }
  }
}
