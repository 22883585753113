@import "../../styles/_colors-variables.scss";

.organization-management {
  max-width: 100%;
  overflow-x: hidden;
  // .top-title-bar
  .top-title-bar {
    padding-bottom: 34px;
    flex-wrap: wrap;
    .left-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #121863;
    }
    .line {
      flex: 0 0 100%;
      height: 4px;
      margin-top: 30px;
      margin-bottom: 35px;
      background: #c9d2df;
    }
    .rights {
      display: flex;
      flex-direction: row;
      align-items: center;
      .right-title {
        color: #121863;
        align-self: flex-end;
        font-size: 32px;
        font-weight: bold;
      }
      .btn {
        margin-left: 16px;
        line-height: 36px;
        min-width: 146px;
        .icons {
          width: 20px;
          margin-right: 10px;
          height: 18px;
        }
        .icon-pdf {
          background: url("/assets/print.svg") center center no-repeat;
          background-size: 20px 24px;
        }
        .icon-csv {
          background: url("/assets/view_list.svg") center center no-repeat;
          background-size: 24px 24px;
        }
      }
    }
  }
  .list-top-bar {
    padding-bottom: 10px;
  }
}
