@import '../../styles/_colors-variables.scss';

.progress-bg {
    min-width: 100px;
    height: 12px;
    border-radius: 12px;
    background: #E5E6F2;
    .bar-blue {
        height: 12px;
        border-radius: 16px;
        background: #101CB9;
    }
}