
.data-table {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0;
  margin: 24px 0;
  max-width: 300px;

  p {
    margin: 0;

    &:nth-child(even) {
      padding-left: 24px;
    }

    &:not(:first-child):not(:nth-child(2)) {
      padding-top: 4px;
      margin-top: 4px;
      border-top: 1px solid #ccc;
    }
  }
}

.line-container {
  max-width: 1000px;
  height: 400px;
}

#metricResults > * {
  & + * {
    margin-top: 30px;
  }
}