.media-preview {
  height: 120px;
  width: 120px;
  overflow: hidden;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .media-preview-delete-button {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0;
    padding: 2px;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
    .icon-trash {
      background: url(/assets/icon-trash.svg) center no-repeat;
      background-size: 20px 20px;
      width: 20px;
      height: 20px;
      &:hover {
        background: url(/assets/icon-trash-blue.svg) center no-repeat;
      }
    }
  }
}
