@import "../../../../styles/_responsive-variables.scss";
@import "../../../../styles/_colors-variables.scss";

// .collection-chart-area-line-chart
.collection-chart-area-line-chart {
  padding-top: 18px;
  padding-bottom: 10px;
  .right-pink-choose {
    display: flex;
    justify-content: flex-end;
  }

  .chart-choose-last-year-module {
    display: flex;
    justify-content: flex-end;

    button {
      padding: 8px 16px;
      border: 0;
      border-radius: 30px;

      display: flex;
      align-items: center;
      margin: 16px 0 24px;

      p {
        margin-left: 8px;
        font-size: 16px;
      }

      &:has(input:checked) {
        background: #0175ff;

        * {
          color: white;
        }
      }
    }
  }
  // .chart-choose-module
  .chart-choose-module {
    height: 36px;
    background-color: #f2947f;
    padding: 2px;
    border-radius: 18px;
    width: 148px;
    display: flex;
    .items {
      padding: 1px 8px;
      flex: 1;
      height: auto;
      border-radius: 18px;
      font-weight: 700;
      line-height: 30px;
      font-size: 16px;
      letter-spacing: 0.015em;
      text-align: center;
      color: #f2947f;
      background-color: #fff;
      &.current {
        color: #ffffff;
        background: #f2947f;
      }
    }
  }
  .line-chart {
    display: flex;
    justify-content: center;
  }

  @media #{$smartphone-breakpoint} {
    .chart-choose-last-year-module {
      button {
        margin: 8px 0;
      }
    }
  }
}
