@import "../../../styles/_colors-variables.scss";

.base-date-input {
  .inputs {
    display: flex;
    align-items: center;
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  .base-date-input-wrapper {
    margin-top: 3px;
    .form-control {
      height: 34px;
      &:focus {
        outline: none;
        box-shadow: none;
        background-color: transparent;
      }
    }
  }
}
