@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

// .white-border-panel
.white-border-panel {
  &.basic-checklist-module {
    padding: 24px 30px;

    @media #{$smartphone-breakpoint} {
      @include spDefaultPadding;
    }
  }
}

.basic-checklist-module {
  .top-bar {
    margin-bottom: 30px;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #121863;
    }
    .rights {
      .letter-txt {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #121863;
        position: relative;
        font-family: "Roboto";
        .letter-square {
          margin-left: 8px;
        }
      }
    }
  }
  .four-items {
    .row {
      align-items: stretch;
      .col {
        display: flex;
        align-items: stretch;
        justify-content: center;
      }
    }
    .question-module {
      .pink-tips {
        left: -38px;
      }
    }
    .groups {
      margin: 30px 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: center;
      .gray-top {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        .txt {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.015em;
          color: #757799;
        }
        .icon-question {
          background: url("/assets/question.svg") no-repeat center center;
          background-size: 16px 16px;
          width: 16px;
          height: 16px;
          display: inline-block;
          margin: 0 !important;
        }
      }
      .content {
        height: 80px;
      }
      .color-txt {
        margin: 20px 0;
        .icon-done {
          background: rgba(122, 198, 63, 0.3) url("/assets/green-done.svg")
            no-repeat center center;
          background-size: 29px 29px;
          margin-left: 4px;
          width: 28px;
          height: 28px;
          margin-right: 8px;
          border-radius: 100%;
          &.disabled {
            background: rgba(220, 91, 62, 0.3) url("/assets/red-not-done.svg")
              no-repeat center center;
            background-size: 29px 29px;
          }
        }

        .icon-error {
          background: url(/assets/error.svg) center center no-repeat;
          background-size: 28px;
          width: 28px;
          height: 28px;
          margin-bottom: -6px;
          margin-right: 4px;
        }
        .txt {
          display: inline-block;
          vertical-align: bottom;
          font-weight: 700;
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.015em;
          color: #121863;
          &.big-txt {
            font-family: "Roboto";
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            margin-right: 4px;
            margin-bottom: -7px;
          }
          &.parent-txt {
            font-size: 16px;
            line-height: 19px;
          }
        }
        &.yellow {
          .txt {
            color: #f7c30e;
          }
        }
        &.red {
          .txt {
            color: #dc5b3e;
          }
        }
        &.green {
          .txt {
            color: #7ac63f;
          }
        }
      }
      .btn-area {
        .btn-border {
          height: 24px;
          min-width: max-content;
          line-height: 20px;
          font-size: 12px;
        }
      }
      .btn-footnote {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        color: #757799;
        min-height: 24px;
      }
    }
  }

  @media #{$smartphone-breakpoint} {
    .four-items {
      .row {
        display: grid;
        grid-gap: 16px;
        align-items: flex-start;
        grid-template-columns: repeat(2, 1fr);

        .groups {
          margin: 0;
          width: 100%;
          justify-content: flex-start;

          .icon-question {
            background: url("/assets/question.svg") no-repeat center center;
            background-size: 16px 16px;
            margin: 0 0 0 4px;
            width: 16px;
            height: 16px;
            display: inline-block;
          }

          .gray-top {
            .txt {
              font-size: 12px;
              letter-spacing: 0;
            }
          }

          .btn-area {
            width: 100%;
          }

          .btn-border {
            padding: 1px 10px;
            width: 100%;
          }
        }
      }
    }

    .footnote {
      margin-top: 8px;
    }
  }
}
