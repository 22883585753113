@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

// .modal-edit-member
.modal-default.modal-edit-member {
  background-color: rgba(0, 0, 0, 0.2);
  .modal-mains {
    margin: 50px auto 50px;
    width: 600px;
    height: auto;
    padding: 40px;
    .btn-close {
      width: 33px;
      height: 33px;
      &:hover {
        opacity: 0.9;
      }
    }
    .bottom-btns {
      .btn {
        min-width: 160px;
      }
    }

    .top-title {
      .rights {
        .icon-del {
          background: url(/assets/blue-del.svg) center no-repeat;
          background-size: 18px 24px;
          width: 18px;
          height: 24px;
          margin-left: 32px;
        }
      }
    }
    .row-form {
      margin: 0 -8px;
      .col {
        padding: 0 8px;
      }
    }
    .radio-groups {
      margin-top: 40px;
      .label-txt {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.015em;
        color: #757799;
        margin-bottom: 12px;
      }
      .two-radio {
        .radio-wrap {
          display: flex;
          align-items: center;
        }
        .radio-wrapper {
          margin-bottom: 16px;
        }
      }
    }
  }

  @media #{$smartphone-breakpoint} {
    .modal-mains {
      @include spDefaultPadding;

      .row-form.row {
        margin-bottom: 16px;
      }

      .radio-groups {
        margin-top: 16px;
        .two-radio {
          .radio-wrap {
            display: grid;
            align-items: center;

            span.gray-text {
              margin-left: 0;
            }
          }
        }
      }

      @include responsiveBottomBtns;
    }
  }
}
