@import '../../../styles/_colors-variables.scss';

// .dashboard-metric-ranking-list-module
.dashboard-metric-ranking-list-module {
    .panel-title {
        font-family: 'Noto Sans JP';
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 20px;
    }
    .table-wrap {
        &.open {
            .show-rows {
                display: table-row;
            }
            .show-more {
                .txt {
                    &.show {
                        display: none;
                    }
                    &.close {
                        display: inline-block;
                    }
                }
                .icon-drop {
                    &.show {
                        display: none;
                    }
                    &.close {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .rank-list-table {
        display: table;
        width: 100%;
        padding-bottom: 25px;
        .show-rows {
            display: none;
            width: 100%;
        }
        .row-th,
        .row-td {
            display: table-row;
            width: 100%;
        }
        .col-td,
        .col-th {
            display: table-cell;
            font-family: 'Roboto';
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.015em;
            color: #121863;
            padding: 10px 0;
            text-align: right;
            &:first-child {
                text-align: left;
                width: 45%;
            }
        }
        .links {
            font-family: 'Noto Sans JP';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            text-decoration-line: underline;
            color: #121863;
            display: inline-block;
        }
        .num {
            font-family: 'Noto Sans JP';
            font-weight: 700;
            font-size: 17px;
            line-height: 25px;
            text-align: center;
            letter-spacing: 0.0015em;
            color: #757799;
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px;
            min-width: 28px;
        }
        .green {
            color: #7AC63F;
        }
        .yellow {
            color: #F7C30E;
        }
        .red {
            color: #DC5B3E;
        }
        .spacing {
            padding: 0 10px;
            word-break: break-word;
        }
    }
    .show-more {
        text-align: center;
        padding: 14px 0 0;
        position: absolute;
        bottom: 32px;
        left: 50%;
        margin-left: -35px;
        .more-link {
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #121863;
            display: inline-block;
        }
        .txt {
            &.show {
                display: inline-block;
            }
            &.close {
                display: none;
            }
        }
        .icon-drop {
            width: 10px;
            height: 7px;
            background: url(/assets/icon-drop.svg) center center no-repeat;
            background-size: 10px 7px;
            margin-left: 5px;
            &.show {
                display: inline-block;
            }
            &.close {
                display: none;
                transform: rotate(-180deg);
            }
        }
    }
}