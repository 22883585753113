@import '../../styles/_colors-variables.scss';

.modal-default {
    &.modal-profile-completion-leaderboard {
        display: block;
        background-color: rgba(0, 0, 0, 0.2);
        .modal-mains {
            height: auto;
            width: auto;
            max-width: 600px;
            margin: 30px auto 30px;
            .top-title {
              margin-bottom: 0;
            }
            .btn-close {
              width: 33px;
              height: 33px;
              &:hover {
                opacity: 0.9;
              }
            }
            .bottom-btns {
              margin-top: 25px;
              .btn-blue {
                min-width: 72px;
              }
            }
            .content {
              white-space: break-spaces;
              font-size: 17px;
              line-height: 27px;
              letter-spacing: 0.005em;
              color: #121863;
              padding-right: 16px;
              .leader-list-table-title {
                font-size: 14px;
                font-family: 'Roboto';
                color: #000;
                display: flex;
                justify-content: flex-start;
                margin-left: 305px;
              }
              .leader-list-table {
                  width: 100%;
                  padding-bottom: 25px;
                  padding-right: 5px;
                  display: flex;
                  flex-wrap: wrap;
                  overflow-y: auto;
                  max-height: 555px;
                  &::-webkit-scrollbar {
                      width: 4px; 
                  }
                  &::-webkit-scrollbar-track {
                       background-color: rgba(201,210,223,0.4);
                       border-radius: 0px;
                  } 
                  &::-webkit-scrollbar-thumb {
                      border-radius: 0px;
                      background-color: #C9D2DF; 
                  }                  
                  &::-webkit-scrollbar-corner {
                      background-color: black;
                  }
                  .row-td {
                      display: flex;
                      flex: 0 0 calc(100% - 40px);
                      margin-left: 20px;
                      margin-right: 20px;
                      border-radius: 16px;
                      border: solid 1px #D7DDE7;
                      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
                      margin-bottom: 10px;
                      &.active {
                          border-color: #3241FF;
                          flex: 0 0 calc(100% - 15px);
                          margin-left: 5px;
                          margin-right: 10px;
                          padding-right: 17px;
                          .spacing {
                              font-size: 18px;
                          }
                          .width-54 {
                              width: 65%;
                          }
                      }
                  }
                  .col-td {
                      font-family: 'Roboto';
                      font-weight: 700;
                      font-size: 14px;
                      line-height: 20px;
                      letter-spacing: 0.015em;
                      color: #121863;
                      padding: 10px 0;
                      text-align: left;
                      &.width-54 {
                          width: 54%;
                      }
                      &.width-24 {
                          width: 24%;
                      }
                      &.width-18 {
                          width: 18%;
                      }
                      &.width-8 {
                          width: 8%;
                      }
                  }
                  .spacing {
                      padding: 0 10px;
                      word-break: break-word;
                      font-size: 16px;
                      height: 24px;
                      display: flex;
                      align-items: center;
                      color: #000;
                      &.name {
                          height: auto;
                          padding-left: 5px;
                      }
                  }
              }
            }
        }
    }
}