@import "../../styles/_colors-variables.scss";

// .left-nav
.left-nav {
  flex: 0 0 84px;
  max-width: 84px;
  padding: 60px 15px 20px 15px;
  position: sticky;
  left: 0;
  top: 0;
  background-color: #fbfbff;
  height: calc(100% - 64px);
  overflow-y: auto;
  z-index: 666;
  align-self: flex-start;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    // background-color: #3241FF;
    width: 100%;
    height: 64px;
  }
  .top-arrow {
    display: none;
    // TODO: background: url('/assets/double-arrow.svg') center center no-repeat;
    background-size: 17px 14px;
    width: 17px;
    height: 14px;
    padding: 15px;
    margin-bottom: 12px;
    pointer-events: none;
  }
  ul {
    li {
      margin-bottom: 15px;
    }
  }
  .nav-items {
    padding: 8px 11px;
    border-radius: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    min-height: 40px;
    margin-bottom: 20px;
    &.active {
      background-color: #3241ff;

      &,
      .txt {
        color: white;
      }
      .icon-dashboard {
        background: url("/assets/icon-dashboard-white.svg") center center
          no-repeat;
        background-size: 18px 18px;
      }
      .icon-mass-post {
        background: url("/assets/icon-edit-white.svg") center center no-repeat;
      }
      .icon-chart {
        background: url("/assets/chart-white.svg") center center no-repeat;
        background-size: 18px 18px;
      }
      .icon-build {
        background: url("/assets/build-white.svg") center center no-repeat;
        background-size: 21px 18px;
      }
      .icon-suggestions {
        background: url("/assets/user-talk-white.svg") center center no-repeat;
        background-size: 20px 18px;
      }
      .icon-setting {
        background: url("/assets/setting-white.svg") center center no-repeat;
        background-size: 20px 20px;
      }
      .icon-user-circle {
        background: url("/assets/user-circle-white.svg") center center no-repeat;
        background-size: 20px 20px;
      }
      .icon-list {
        background: url("/assets/icon-list-white.svg") center center no-repeat;
        background-size: 18px 10px;
      }
      .icon-trash {
        background: url("/assets/icon-trash-white.svg") center center no-repeat;
        background-size: 14px 18px;
      }
      .icon-email {
        background: url("/assets/email-white.svg") center center no-repeat;
        background-size: 20px 16px;
      }
    }
    .icons {
      width: 22px;
      height: 20px;
      flex: 0 0 22px;
      max-width: 22px;
      margin-top: 4px;
    }
    .txt {
      display: none;
      color: #3241ff;
      font-weight: 700;
    }
    .icon-dashboard {
      background: url("/assets/icon-dashboard.svg") center center no-repeat;
      background-size: 18px 18px;
    }
    .icon-mass-post {
      background: url("/assets/icon-edit-blue.svg") center center no-repeat;
    }
    .icon-chart {
      background: url("/assets/chart.svg") center center no-repeat;
      background-size: 18px 18px;
    }
    .icon-build {
      background: url("/assets/build.svg") center center no-repeat;
      background-size: 21px 18px;
    }
    .icon-suggestions {
      background: url("/assets/user-talk.svg") center center no-repeat;
      background-size: 20px 18px;
    }
    .icon-setting {
      background: url("/assets/setting.svg") center center no-repeat;
      background-size: 20px 20px;
    }
    .icon-user-circle {
      background: url("/assets/user-circle.svg") center center no-repeat;
      background-size: 20px 20px;
    }
    .icon-list {
      background: url("/assets/icon-list.svg") center center no-repeat;
      background-size: 18px 10px;
    }

    .icon-trash {
      background: url("/assets/icon-trash-blue.svg") center center no-repeat;
      background-size: 14px 18px;
    }

    .icon-email {
      background: url("/assets/email.svg") center center no-repeat;
      background-size: 20px 16px;
    }
  }

  .ghost-mode-header {
    h3 {
      font-size: 22px;
      background: #f00;
      padding: 8px;
      border-radius: 50px;
      margin-bottom: 0.5em;
      color: white;
      text-align: center;
    }

    ~ ul {
      li:last-child {
        padding-top: 0.5em;
        margin-top: 0.5em;
        border-top: 1px solid rgba(33, 37, 41, 0.25);
      }
    }
  }
}
