@import "../../../styles/_responsive-variables.scss";
@import "../../../styles/_colors-variables.scss";

// .modal-invite-new-member
.modal-default.modal-invite-new-member {
  background-color: rgba(0, 0, 0, 0.2);
  .modal-mains {
    margin: 50px auto 50px;
    width: 600px;
    height: auto;
    padding: 32px 40px;
    .btn-close {
      width: 32px;
      height: 32px;
      &:hover {
        opacity: 0.9;
      }
    }
    .bottom-btns {
      .btn {
        min-width: 160px;
      }
    }
    .row-form {
      margin-bottom: 40px;
      &.row {
        margin: 0 -8px 40px;
      }
      .col {
        padding: 0 8px;
      }
      &.mb60 {
        margin-bottom: 40px;
      }
    }
    .radio-groups {
      margin-bottom: 16px;
      .label-txt {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.015em;
        color: #757799;
        margin-bottom: 12px;
      }
      .two-radio {
        .radio-wrap {
          display: flex;
          align-items: center;
        }
        .radio-wrapper {
          margin-bottom: 16px;
        }
      }
    }
  }
  @media #{$smartphone-breakpoint} {
    .modal-mains {
      @include spDefaultPadding;

      .title-bar.mb60 {
        margin-bottom: 16px;
      }

      .row-form.row {
        margin-bottom: 16px;
      }

      .radio-groups {
        margin-top: 16px;
        .two-radio {
          .radio-wrap {
            display: grid;
            align-items: center;

            span.gray-text {
              margin-left: 0;
            }
          }
        }
      }

      @include responsiveBottomBtns;
    }
  }
}
